/* ------- App ------- */

.App {
  overflow: hidden;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  max-width: 100vw;
}

/* ------- Navbar ------- */

.navbar {
  display: flex;
  justify-content: space-evenly;
}

.nav-scrolled {
  background-color: #FCFCFC;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.nav-link {
  color: #010037 !important;
  font-weight: 800;
  font-size: larger;
}

.logo {
  width: 70%;
}

.navbar-logo {
  max-width: 300px;
}

@media only screen and (max-width: 405px) {
  .navbar-logo {
    max-width: 250px;
  }
}

@media only screen and (max-width: 355px) {
  .navbar-logo {
    max-width: 200px;
  }
}

/* ------- Banner ------- */

.banner-card {
  top: 26rem;
  margin-left: 20%;
  text-align: left;
  background-color: #fcb006;
  padding: 2rem;
  border-radius: 25px 0px 0px 25px;
}

.banner-card-content {
  max-width: 32rem;
  font-size: 1.5rem;
  color: #040237;
}

.banner-card-title {
  color: white;
  font-size: 3.5rem;
  font-weight: 800;
}

.banner-lines {
  display: none;
}

.banner-iphone-container {
  position: relative;
  top: 15rem;
  width: 17rem;
  z-index: 2;
  left: 66%;
}


.banner-iphone-header {
  height: 4.5rem;
  left: 6%;
  top: 2%;
  width: 16.5rem;
  position: absolute;
  background-image: url("./images/phone/rtv-header.png");
  border-radius: 30px 30px 0px 0px;
  background-repeat: no-repeat;
  background-size: 97% 99%;
}

.banner-iphone-footer {
  /* background-image: url("./images/phone/rtv-footer.jpg");
  background-size: 98% 99%;
  bottom: 2%;
  height: 3.5rem;
  left: 5%;
  width: 17rem;
  position: absolute;
  border-radius: 0px 0px 30px 30px;
  background-repeat: no-repeat; */
}

.banner-iphone-edge {
  height: 36rem;
  position: absolute;
  z-index: 2;
  border-radius: 43px;
}

.banner-iphone {
  background-image: url("./images/phone/rtv-long.png");
  background-repeat: no-repeat;
  animation: bannerIphone 2s linear infinite;
  animation-play-state: paused;
  /* background-position: left 54% top 285%; */
  background-size: 88% 97%;
  height: 36rem;
  z-index: 1;
  border-radius: 43px;
}

@keyframes bannerIphone {
  from { background-position: left 54% top 285%; }
  to { background-position: left 54% top 25%; }
}

/* ------- About ------- */

.about {
  background-color: #040237;
  height: 100%;
  background-size: cover;
  margin-bottom: -5%;
  margin-top: -2%;
}

.about-text {
  position: relative;
  top: 5rem;
  left: 5%;
  color: white;
  text-align: left;
  max-width: 43rem;
  padding: 2rem;
  font-size: 1.5rem;
  z-index: 2;
}

.about-title {
  font-weight: 800;
  font-size: 3.5rem;
  color: #f93655;
}

.about-circles {
  height: 20rem;
  position: relative;
  left: 30%;
  bottom: 15rem;
  z-index: 1;
}

.about-lines {
  transform: rotate(180deg);
  width: 60rem;
  position: relative;
  bottom: 10rem;
  right: 36%;
}

/* ------- Development ------- */

.development-front {
  min-height: 100%;
  background: linear-gradient(270deg, rgba(43,172,149), rgba(255,0,0,0) 300%);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
  padding: 7% 0% 7% 0%;
}

.development-text {
  color: #010037;
  text-align: left;
  max-width: 35rem;
  padding: 2rem;
  font-size: 1.5rem;
}

.development-title {
  font-weight: 800;
  font-size: 3.5rem;
}

.code-icon {
  height: 12rem;
}

.development-lines {
  width: 60rem;
  position: relative;
  bottom: 16%;
  left: 25%;
}

.vertical-lines {
  height: 15rem;
  position: relative;
  left: 35%;
  top: 30rem;
  z-index: 1;
}

/* ------- ManagedServices ------- */

.managed-services {
  background-color: #040237;
  height: 100%;
  background-size: 'cover';
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}

.managed-services-text {
  color: white;
  text-align: left;
  max-width: 33rem;
  padding: 2rem;
  font-size: 1.5rem;
  margin: 0% 5% 0% 5%;
}

.managed-services-title {
  font-weight: 800;
  font-size: 3.5rem;
  color: white;
  line-height: 1.2;
}

.managed-services-circles {
  height: 35rem;
  position: relative;
  bottom: 3rem;
}

.managed-services-iphone-container {
  position: absolute;
  top: 63%;
  right: 65%;
}

.managed-services-iphone-header {
  height: 6.5rem;
  left: 5.5%;
  top: 2%;
  width: 17rem;
  position: absolute;
  border-radius: 30px 30px 0px 0px;
  background-image: url("./images/phone/mansionbet-header.png");
  background-size: 94% 100%;
  background-repeat: no-repeat;
}

.managed-services-iphone-footer {
  background-image: url("./images/phone/mansionbet-footer.png");
  background-size: 98% 99%;
  bottom: 2.5%;
  height: 3.5rem;
  left: 4%;
  width: 17rem;
  position: absolute;
  border-radius: 0px 0px 30px 30px;
  background-repeat: no-repeat;
}

.managed-services-iphone-edge {
  height: 36rem;
  position: absolute;
  z-index: 2;
  border-radius: 43px;
}

.managed-services-iphone {
  background-image: url("./images/phone/mansionbet-long.webp");
  background-repeat: no-repeat;
  animation: managedServicesIphone 7s linear infinite;
  animation-play-state: paused;
  background-position: left 48% top -16%;
  background-size: 87% 225%;
  height: 36rem;
  border-radius: 43px;
}

@keyframes managedServicesIphone {
  from { background-position: left 48% top -16%; }
  to { background-position: left 48% top 109.5%; }
}

/* ------- UXDesign ------- */

.ux-design-front {
  min-height: 100%;
  background: linear-gradient(270deg, rgba(26,132,173), rgba(255,0,0,0) 300%);
}

.ux-design-text {
  color: white;
  text-align: left;
  max-width: 40rem;
  padding: 2rem;
  font-size: 1.5rem;
  position: relative;
  top: 6rem;
  left: 44%;
}

.ux-design-title {
  color: #010037;
  font-weight: 800;
  font-size: 3.5rem;
}

.ux-design-lines {
  width: 60rem;
  position: relative;
  left: 25%;
  top: 4rem;
}

.dotted-block {
  height: 12rem;
  position: relative;
  right: 38%;
  top: 6rem;
  z-index: 1;
}

/* ------- VDIntegration ------- */

.vd-integration {
  background-color: #040237;
  height: 100%;
  position: relative;
}

.vd-integration-card {
  text-align: left;
  background-color: #FF224B;
  padding: 3rem;
  border-radius: 25px 25px 25px 25px;
  color: #010037;
  max-width: 44rem;
  position: relative;
  top: 8rem;
  left: 38%;
  z-index: 1;
  margin-bottom: -20%;
}

.vd-integration-card-body a {
  color: #010037;
}

.vd-integration-card-content {
  max-width: 38rem;
  font-size: 1.5rem;
  padding-bottom: 8%;
  color: whitesmoke;
}

.vd-integration-card-title {
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.2;
  color: #010037;
}

.vd-integration-circles {
  height: 18rem;
  position: relative;
  bottom: 4rem;
  right: 5%;
}

.vd-integration-screens {
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  top: 8.5rem;
  left: 6%;
}

.vd-integration-monitor {
  z-index: 1;
  height: 46rem;
  position: relative;
  left: 4%;
  background-image: url("./images/the-dogs-desktop.png");
  background-repeat: no-repeat;
  background-position: center 10%;
  background-size: 99% 64%;
}

.vd-integration-tablet {
  z-index: 1;
  height: 34rem;
  position: relative;
  right: 12%;
  top: 2rem;
  background-image: url("./images/the-dogs-tablet.png");
  background-repeat: no-repeat;
  background-position: center 25%;
  background-size: 84% 68%;
}

.vd-integration-phone {
  z-index: 1;
  height: 24rem;
  position: relative;
  right: 17%;
  top: 2.5rem;
  background-image: url("./images/the-dogs-mobile.png");
  background-repeat: no-repeat;
  background-position: center 24%;
  background-size: 88% 72%;
}

/* ------- MobileApps ------- */


.mobile-apps {
  background-color: #2bac95;
  height: 45em;
  position: relative;
}

.mobile-apps-text {
  color: white;
  text-align: left;
  max-width: 38rem;
  padding: 2rem;
  font-size: 1.5rem;
  position: relative;
  top: 5em;
  left: 44%;
  z-index: 2;
}

.mobile-apps-title {
  color: #010037;
  font-weight: 800;
  font-size: 3.5rem;
}

.mobile-apps-circles {
  height: 30rem;
  position: relative;
  left: 48%;
  top: -10rem;
  z-index: 1;
}

.mobile-apps-iphone-container {
  position: absolute;
  right: 63.5%;
  top: 6.5%;
  z-index: 2;
}

.mobile-apps-iphone-header {
  height: 6.5rem;
  left: 5.5%;
  top: 2%;
  width: 17.5rem;
  position: absolute;
  border-radius: 30px 30px 0px 0px;
  background-image: url("./images/phone/49s-header.png");
  background-size: 94% 100%;
  background-repeat: no-repeat;
}

.mobile-apps-iphone-footer {
  /* background-image: url("./images/phone/49s-long.png");
  background-size: 98% 99%;
  bottom: 2.5%;
  height: 3.5rem;
  left: 3%;
  width: 17rem;
  position: absolute;
  border-radius: 0px 0px 30px 30px;
  background-repeat: no-repeat; */
}

.mobile-apps-iphone-edge {
  height: 36rem;
  position: absolute;
  z-index: 2;
  border-radius: 43px;
}

.mobile-apps-iphone {
  background-image: url("./images/phone/49s-long.png");
  background-repeat: no-repeat;
  animation: mobileAppsIphone 2s linear infinite;
  animation-play-state: paused;
  background-position: left 53% top -173%;
  background-size: 87.5% 109%;
  border-radius: 43px;
  height: 36rem;
}

@keyframes mobileAppsIphone {
  from { background-position: left 53% top -195% }
  to { background-position: left 53% top 116%; }
}

/* ------- Clients ------- */

.clients {
  background-color: white;
  height: 100%;
}

.clients-text {
  position: relative;
  top: 4rem;
  left: 5%;
}

.clients-text-title {
  color: #fcb006;
  font-weight: 800;
  font-size: 3.5rem;
}

.client-logos {
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.client-logos-row-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.client-logos-row-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.client-logos img {
  margin: 1%;
}

.client-logos-row-2 img {
  margin: 1%;
}

.client-logos-row-3 img {
  margin: 1%;
}

.rtv-logo {
  height: 3rem;
}

.coursetrack-logo {
  height: 4rem;
}

.grnsw-logo {
  height: 4rem;
  border-radius: 5px;
}

.gapnsw-logo {
  height: 4rem;
  border-radius: 5px;
}

.fortynines-logo {
  height: 5rem;
}

.sis-logo-client {
  height: 4.5rem;
}

.the-dogs-logo {
  height: 3.5rem;
}

.venues-live-logo {
  height: 3rem;
  border-radius: 5px;
}

.nyra-logo {
  height: 3rem;
}

.twin-spires-logo {
  height: 7rem;
}

.tab-logo {
  height: 3rem;
}

.sportium-logo {
  height: 3rem;
}

.racing-post-logo-clients {
  height: 7rem;
}

.bloodstock-news-logo {
  height: 5rem
}

.rise-racing-logo {
  height: 5rem
}

.bosca-sports-logo {
  height: 3rem
}

/* ------- Banner3 ------- */

.banner3-lines {
  display: none;
}

/* ------- PartnerIntegrations ------- */

.partner-integrations {
  background-color: white;
  height: 100%;
}

.partner-integrations-text {
  text-align: left;
  padding: 2rem;
  position: relative;
  top: 60%;
  left: 10%;
}

.partner-integrations-title {
  color: #fcb006;
  font-weight: 800;
  font-size: 3.5rem;
}

.partner-integrations-title-top {
  margin-bottom: -1rem;
}

.partner-logos1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 5%;
}

.partner-logos2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.partner-logos3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 5%;
}

.partner-logos1 img {
  margin: 2% 2% 2% 2%;
}

.partner-logos2 img {
  margin: 2% 2% 2% 2%;
}

.partner-logos3 img {
  margin: 2% 2% 2% 2%;
}

.firebase-logo {
  height: 2rem;
}

.aap-logo {
  height: 5rem;
}

.aws-logo {
  height: 4rem;
}

.pa-logo {
  height: 2rem;
}

.simple-stream-logo {
  height: 2rem;
}

.sky-logo {
  height: 4rem;
}

.sport-radar-logo {
  height: 1rem;
}

.contentful-logo {
  height: 2rem;
}

.opta-logo {
  height: 2rem;
}

.paywizard-logo {
  height: 3rem;
}

.perform-logo {
  height: 3rem;
}

.rmg-logo {
  height: 3rem;
}

.timeform-logo {
  height: 3rem;
}

.racing-post-logo-partners {
  height: 1rem;
}

.draft-kings-logo {
  height: 4rem;
}

.grv-logo {
  height: 4.5rem;
}

.oddschecker-logo {
  height: 2rem;
}

.cloudflare-logo {
  height: 3rem;
}

.github-logo {
  height: 3rem;
}

.playtech-logo {
  height: 3rem;
}

/* ------- On Space ------- */

.on-space {
  background-color: #2bac95;
  height: 100%;
  background-size: cover;
  margin-bottom: -5%;
  margin-top: -2%;
}

.on-space-intro {
  max-width: 43rem;
}

.on-space-icons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  flex: 0.9;
  max-width: 90%;
}

.on-space-text {
  position: relative;
  top: 5rem;
  left: 5%;
  color: white;
  text-align: left;
  /* max-width: 60rem; */
  padding: 2rem;
  font-size: 1rem;
  z-index: 2;
}

.cloudflare-logo {
  height: 3rem;
}

.github-logo {
  height: 3rem;
}

.playtech-logo {
  height: 3rem;
}

/* ------- Contact ------- */

.contact-component {
  background-color: #040237;
  height: 100%;
}

.contact-dots {
  position: relative;
  top: -10rem;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15%;
}

.contact-header {
  color: #FB1943;
  font-weight: 1000;
  font-size: 3rem;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 5%;
}

.contact-text {
  color: white;
  text-align: left;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 50%;
}

.contact-content-container {
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 100%;
  margin-top: 10%;
}

.office-locations {
  width: 30%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.office-address {
  margin-bottom: 5%;
}

.office {
  color: #FB1943;
  line-height: 50%;
  font-weight: bold;
  text-align: left;
}

.office-text {
  color: white;
  line-height: 50%;
  text-align: left;
  font-weight: 600;
}

#contact-form {
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
  grid-gap: 8%;
}

.grid-section {
  grid-column: span 2;
  grid-row: span 1;
  display: flex;
  flex-direction: column;
}

.grid-section-final {
  grid-column: span 4;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50%;
}

.form-label {
  color: white;
  text-align: left;
  font-weight: 600;
  margin-bottom: -0.5%;
}

.form-input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid white;
  color: white;
  font-weight: 600;
}

.contact-form-button {
  background-color:#FB1943;
  height: 3rem;
  width: 8rem;
  border-radius: 100px;
  color: white;
  grid-column: 4;
  font-weight: 600;
  margin-top: 10%;
}

.submission-status-good {
  display: none;
  color: #FB1A43;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Nunito';
  margin-right: 8%;
}

.terms-and-conditions {
  color: white;
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  margin-bottom: 2em;
}

.terms-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.pipe {
  margin: 0em 1em 0em 1em;
}

.p-underlined {
  text-decoration: underline;
}

.p-no-margin {
  margin: 0;
}

/* ----------------------- MEDIA QUERIES ----------------------- */


/* ------- Mobile Devices ------- */

@media only screen and (max-width: 700px) {

  .banner-card-title, .about-title, .development-title, .managed-services-title, .ux-design-title, .vd-integration-card-title, .mobile-apps-title, .clients-text-title, .partner-integrations-title  {
    font-size: 2.5rem;
  }

  .banner {
    background-position: center;
  }

  .banner-iphone-container {
    top: 22rem;
    left: 22%;
    width: 17rem;
  }

  .banner-iphone-edge {
    height: 24rem;
    border-radius: 27px;
  }

  .banner-iphone-header {
    height: 3rem;
    left: 17%;
    top: 2%;
    width: 21.5rem;
    position: absolute;
    border-radius: 25px 129px 0px 0px;
    background-size: 50% 99%;
    background-position: left 2% top 0%;
    z-index: 0;
  }

  .banner-iphone-footer {
    background-size: 85% 99%;
    bottom: 2%;
    height: 2.5rem;
    left: 17%;
    width: 13rem;
    border-radius: 0px 0px 30px 25px;
  }

  .banner-iphone {
    height: 24rem;
    left: 5%;
    top: 22rem;
    border-radius: 27px;
    background-position: left 54% top 285%;
    animation: bannerIphone 1500ms linear infinite;
  }

  @keyframes bannerIphone {
    from { background-position: left 54% top 285%; }
    to { background-position: left 54% top -145%; }
  }

  .banner-card {
    top: 23rem;
    margin-left: 10%;
  }

  .banner-card-content {
    font-size: 1.1rem;
  }

  .banner-lines {
    /* width: 30rem; */
    display: none;
  }

  .about {
    margin-bottom: 0%;
    padding-bottom: 38%;
    padding-top: 42%;
    margin-top: 10.5%;
  }

  .about-text {
    top: 9rem;
    left: 0%;
    font-size: 1.1rem;
  }

  .about-circles {
    height: 8rem;
    bottom: 0%;
    top: 6.5rem;
    left: 0%;
  }

  .about-lines {
    display: none;
  }

  .development-front {
    flex-direction: column;
    justify-content: center;
  }

  .code-icon {
    height: 8rem;
    margin-bottom: 5%;
  }

  .development-lines {
    display: none;
  }

  .development-text {
    font-size: 1.1rem;
  }

  .banner2 {
    background-position: top center;
  }

  .vertical-lines {
    height: 4rem;
    top: 95%;
    left: 35%;
  }

  .managed-services {
    flex-direction: column;
    justify-content: space-around;
  }

  .managed-services-text {
    font-size: 1.1rem;
    position: relative;
    bottom: 2rem;
    margin: 5% 0% 30% 0%;
  }

  .managed-services-body {
    margin-top: 5%;
  }

  .managed-services-circles {
    height: 18rem;
    bottom: 1.5rem;
  }

  .managed-services-iphone-container {
    top: 78.5%;
    right: 26%;
  }

  .managed-services-iphone-edge {
    height: 26rem;
    border-radius: 30px;
  }

  .managed-services-iphone-header {
    height: 5rem;
    border-radius: 20px 30px 0px 0px;
    background-size: 96% 100%;
    width: 12rem;
  }

  .managed-services-iphone-footer {
    width: 12rem;
    left: 3.5%;
    height: 3rem;
    border-radius: 0px 0px 20px 30px;
    background-size: 101% 99%;
  }

  .managed-services-iphone {
    height: 26rem;
    right: 25%;
    top: 79%;
    border-radius: 30px;
  }

  .ux-design-front {
    padding-bottom: 70%;
  }

  .ux-design-text {
    top: 16rem;
    left: 0%;
    font-size: 1.1rem;
  }

  .ux-design-lines {
    display: none;
  }

  .dotted-block {
    display: none;
  }

  .vd-integration {
    padding-left: 5%;
    padding-right: 5%;
  }

  .vd-integration-card {
    left: 0%;
    padding: 2rem;
    top: 4rem;
  }

  .vd-integration-card-content {
    font-size: 1.1rem;
    padding-bottom: 15%;
  }

  .vd-integration-card-body {
    margin-top: 5%;
  }

  .vd-integration-circles {
    height: 14rem;
    position: relative;
    bottom: -4.5rem;
    z-index: 1;
    right: 0%;
    left: 15%;
  }

  .vd-integration-screens {
    display: none;
  }

  .vd-integration-monitor {
    display: none;
  }

  .vd-integration-tablet {
    display: none;
  }

  .vd-integration-phone {
    display: none;
  }

  .mobile-apps-text {
    left: 0%;
    font-size: 1.1rem;
    top: 2em;
  }

  .mobile-apps-circles {
    height: 16rem;
    top: -10rem;
  }

  .mobile-apps-iphone-container {
    right: 38.5%;
    top: 40.5%;
  }
/*
  .mobile-apps-iphone-edge {
    height: 36rem;
    border-radius: 29px;
  } */

  .mobile-apps-iphone-footer {
    width: 12.5rem;
    height: 2.5rem;
    left: 2%;
  }

  .client-logos {
    padding: 40% 2% 2% 2%;
  }

  .client-logos-row-2 {
    padding: 2% 2% 2% 2%;
  }

  .client-logos-row-3 {
    padding-bottom: 12%;
  }

  .client-logos img {
    margin: 3%;
  }

  .client-logos-row-2 img {
    margin: 3%;
  }

 .client-logos-row-3 img {
    margin: 3%;
  }

  .banner3 {
    height: 70vh !important;
  }

  .banner3-lines {
    /* width: 30rem; */
    display: none;
  }

  .clients-text {
    top: 13rem;
    left: -18%;
    padding-top: 5%;
  }

  .partner-integrations-text {
    top: 70%;
    left: 0%;
  }

  .partner-logos1 {
    padding: 5% 4% 0% 4%;
  }

  .contact {
    margin-top: -5%;
  }

  .contact-container {
    width: 80%;
  }

  .contact-dots {
    top: -4rem;
  }

  .contact-header  {
    font-size: 1.5rem;
  }

  .contact-text {
    font-size: 1.1rem;
    line-height: 100%;
  }

  .contact-br {
    display: none;
  }

  .contact-content-container {
    display: flex;
    flex-direction: column;
  }

  .office-locations {
    width: 110%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8%;
  }
  .office {
    line-height: 100%;
  }

  .office-text {
    font-size: 0.8rem;
    line-height: 100%;
    margin-bottom: 4px;
  }

  .melbourne {
    padding-left: 7%;
  }

  .uk {
    padding-left: 4%;
  }

  .uk p:nth-child(2) {
    padding-top: 14%;
  }

  #contact-form {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .button-container {
    margin-right: -20%;
  }

  .contact-form-button {
    margin-top: 15%;
  }

  .grid-section {
    grid-column: span 4;
    grid-row: span 1;
    display: flex;
    flex-direction: column;
  }

  .terms-and-conditions {
    margin-top: 65%;
  }
}

/* ------- Tablet Devices ------- */

@media only screen and (max-width: 1030px) and (min-width: 700px) {
  .banner {
    margin-bottom: 0% !important;
  }

  .banner-card {
    top: 30rem;
  }

  .banner-iphone-container {
    left: 53%;
    top: 27.5rem;
  }

  .banner-iphone {
    top: 27.5rem;
    left: 21%;
    border-radius: 41px;
  }

  .about,
  .on-space {
    padding-top: 0%;
    padding-bottom: 12%;
    margin-top: 60%;
  }

  .about-text {
    font-size: 1.3rem;
  }

  .about-circles {
    left: 16%;
    top: 3rem;
  }

  .about-lines {
    bottom: 2rem;
  }

  .development-front {
    padding: 7% 3% 7% 3%;
  }

  .code-icon {
    height: 8rem;
  }

  .development-text {
    font-size: 1.3rem;
  }

  .vertical-lines {
    height: 8rem;
    top: 34rem;
  }

  .managed-services {
    padding: 10% 8% 10% 8%;
  }

  .managed-services-circles {
    height: 20rem;
    position: relative;
    bottom: 9rem;
    left: 0%;
  }

  .managed-services-iphone-container {
    top: 42%;
    right: 62.5%;
  }

  .managed-services-iphone-edge {
    height: 34rem;
  }

  .managed-services-iphone-header {
    width: 16rem;
    left: 6%;
  }

  .managed-services-iphone-footer {
    width: 16rem;
    height: 4rem;
  }

  .managed-services-iphone {
    top: 41%;
    right: 63%;
    height: 34rem;
    border-radius: 43px;
  }

  .managed-services-text {
    font-size: 1.3rem;
    margin: 0% 0% 0% 4%;
  }

  .ux-design-front {
    padding-bottom: 5%;
  }

  .ux-design-text {
    font-size: 1.3rem;
    top: 11.5rem;
    left: 18%;
  }

  .dotted-block {
    right: 41%;
    top: 7.5rem;
    height: 10rem;
  }

  .vd-integration-circles {
    display: none;
  }

  .vd-integration-card {
    top: 8rem;
    left: 8%;
    margin-bottom: -5%;
  }

  .vd-integration-card-content {
    font-size: 1.3rem;
    padding-bottom: 4%;
  }

  .vd-integration-monitor {
    height: 40rem;
    left: -6%;
  }

  .vd-integration-tablet {
    display: none;
  }

  .vd-integration-phone {
    display: none;
  }

  .mobile-apps-iphone-container {
    left: 15%;
    top: 23rem;
  }

  .mobile-apps-text {
    font-size: 1.3rem;
    top: 2em;
    left: 15%;
  }

  .client-logos {
    padding-top: 35%;
  }

  .client-logos-row-3 {
    padding-bottom: 12%;
  }

  .clients-text {
    top: 16rem;
    left: -30%;
  }

  .partner-integrations-text {
    top: 68%;
    left: 2%;
  }

  .contact {
    padding-bottom: 4%;
  }

  .button-container {
    margin-right: -50%;
  }

}

/* ------- X-large Screens ------- */

@media only screen and (min-width: 1431px) {
  .banner-iphone {
    left: 23%;
  }

  .banner-card {
    margin-left: 25%;
  }

  .about {
    margin-top: -5%;
  }

  .about-circles {
    left: 55%;
  }

  .about-lines {
    bottom: 0rem;
    right: 50%;
  }

  .vd-integration-tablet {
    right: 7%;
  }

  .vd-integration-phone {
    right: 10%;
  }
}
